<template>
  <div class="wrap">
    <div class="content bg">
      <div class="news">
        <h2>武汉市长周先旺调研武汉软件新城<span>2020-04-10</span></h2>
        <div class="new-con">
          <p>4月9日，武汉市委副书记、市长周先旺来到东湖新技术开发区调研生物医药企业复工复产情况，武汉软件新城（光谷智慧健康园）企业天亿集团健康智谷作为武汉生物医药企业的代表接受调研。武汉市委常委、常务副市长胡亚波，武汉市委常委、东湖高新区党工委书记汪祥旺、武汉市人大常委会副主任但长春，东湖新技术开发区管委会主任陈平等参加调研。</p>
          <p><img src="../../assets/images/news/new2-img1.png" alt=""></p>
          <p>在园区企业天亿集团，周先旺询问企业复工复产情况，以及在复工复产中遇到的困难和问题。他指出，武汉生物医疗企业在武汉疫情防控中作出了重要贡献，市直相关部门要指导企业继续抓紧抓实抓细各项防控工作，同时要加强与企业对接，强化跟踪服务，加大政策扶持和落实力度，抓紧扫除企业复工复产中的各种障碍，加快推动生物医药企业复工复产。</p>
          <p><img src="../../assets/images/news/new2-img2.png" alt=""></p>
          <p>武汉软件新城（光谷智慧健康园）于3月25日即取得了复工批准，成为武汉首批复工的园区，从疫情爆发到目前为止园区无一例感染。针对企业复工复产需求，在实施严格防控的同时，园区制定了详细的《企业复工复产防疫指南》，对企业申报复工、防疫物资准备、物业消杀、员工就餐、员工返工信息登记、出行安排等需求制定了详细的制度，指导企业安全、高效复工。</p>
          <p><img src="../../assets/images/news/new2-img3.png" alt=""></p>
          <p>在这场防疫阻击战中，光谷智慧健康园企业纷纷发挥所长，为武汉疫情防控提供物资援助、技术支持。例如希望组积极开展COVID-19感染患者基因组变异研究，英特沃德承担一线医院制氧设备供应保障，麦格米特为“火神山”医院紧急提供医疗电源模块，海沁医疗捐赠氢氧呼吸机和防护服等，展现出极强的企业担当。</p>
          <p>光谷智慧健康园由光谷生物城与武汉软件新城共同打造，定位生物信息、基因测序、医疗大数据、高端医疗器械研发及生产、检测检验、健康管理、精准医疗等智慧健康产业。目前已吸引了天亿集团、致众科技、百迈克、荣晖数字医疗、未来组生物科技等为代表的智慧健康医疗领域创新领域领军企业。</p>
        </div>
        <div class="source"><a href="https://mp.weixin.qq.com/s/hOvLBpfsLlSrylvU49ubKw" target="_blank">阅读原文</a></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'News'
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/banner4.png") no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url("../../assets/images/banner4-icon.png") no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 60px 0;
  margin-top: 76px;
  .news {
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    h2 {
      text-align: center;
      margin-bottom: 30px;
      span {
        display: block;
        margin: 10px auto 0;
        font-size: 14px;
      }
    }
    .new-con {
      width: 80%;
      margin: 0 auto;
      p {
        margin-bottom: 30px;
        text-indent: 2em;
        line-height: 2.2;
        img {
          display: block;
          margin: 0 auto;
        }
        &.tac{
          text-align: center;
          margin-top: -30px;
        }
      }
    }
    .source a {
      font-size: 12px;
      color: #434343;
    }
  }
}
</style>
